import React, { useEffect, useMemo, useState } from 'react';
import loadable from '@loadable/component';
import { navigate } from 'gatsby';

import { ButtonBase } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AddHomeWorkIcon from '@mui/icons-material/AddHomeWork';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';

import { Layout } from '@components/layout/desktop/Layout';
import { Topbar } from '@desktop/home/components/Topbar';
import { Protected } from '@components/auth';
import { IsNotDesktop } from '@components/layout/desktop/IsNotDesktop';

import { useAuth } from '@context';

function Index(props) {
  const auth = useAuth();

  const buttons = useMemo(
    () => [
      { title: 'Buscar Vagas', path: '/desktop/vagas', Icon: SearchIcon, permission: [0, 1, 2] },
      { title: 'Postar Vagas', path: '/', Icon: AddIcon, permission: [0, 2] },
      { title: 'Contato dos Gestores', path: '/', Icon: WorkHistoryIcon, permission: [1, 2] },
      { title: 'Vagas Postadas', path: '/desktop/vagas/postar_vaga', Icon: EventAvailableIcon, permission: [0, 2] },
      { title: 'Minhas Instituições', path: '/', Icon: AddHomeWorkIcon, permission: [0, 2] },
      { title: 'Escala Médica', path: '/desktop/escalas', Icon: CalendarMonthIcon, permission: [0, 1, 2] },
      // { title: 'Relógio de Ponto', path: '/ponto', Icon: ScheduleIcon, disabled: true, permission: [0, 1, 2] },
    ],
    []
  );

  return (
    <Protected>
      <IsNotDesktop>
        <Layout {...props}>
          <>
            {/* <div className="w-full h-full flex justify-center items-center">
              <div className="flex flex-wrap gap-8 w-[675px]">
                {auth?.userInfo &&
                  buttons.map((item) => {
                    if (item.permission.includes(auth?.userInfo.selected)) {
                      return <HomeButton {...item} />;
                    }
                  })}
              </div>
            </div> */}
          </>
        </Layout>
      </IsNotDesktop>
    </Protected>
  );
}

const HomeButton = ({ Icon, title, path }) => (
  <ButtonBase
    className={`
    px-8 py-6 w-80 border border-gray4/30 border-solid
    rounded-2xl text-gray3 justify-start items-center gap-6
    hover:text-blue1
  `}
    onClick={() => navigate(path)}
  >
    <Icon className="w-12 h-12" />
    <p className="text-2xl text-left font-bold w-32">{title}</p>
  </ButtonBase>
);

export default Index;
